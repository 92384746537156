<template>
  <div class="[ container mx-auto mt-10 lg:mt-17 mb-35 ] [ flex flex-col ]">
    <h2 class="px-6 md:px-0 md:text-center text-xl-1 md:text-4xl whitespace-nowrap">
      {{ t('title') }}
    </h2>

    <ClientOnly>
      <Swiper
        :modules="[SwiperNavigation]"
        :slides-per-view="2.5"
        :space-between="15"
        :slides-offset-before="20"
        :scrollbar="{ el: '.swiper-scrollbar', hide: false }"
        :breakpoints="{
          '768': {
            slidesPerView: 3,
            spaceBetween: 20,
            centeredSlidesBounds: false,
            centerInsufficientSlides: false,
            slidesPerGroup: 4.4,
            slidesOffsetBefore: 0,
          },
          '1024': {
            slidesPerView: 5,
            spaceBetween: 20,
            centeredSlidesBounds: true,
            centerInsufficientSlides: true, // !!(props.alignment === 'center'),
            centeredSlides: true,
            slidesOffsetBefore: 0,
            slidesPerGroup: 5,
            scrollbar: { el: '.swiper-scrollbar', hide: true },
          },
        }"
        class="mt-7 md:mt-10 w-full"
      >
        <SwiperSlide v-for="category in filteredCategories" :key="category.id || ''">
          <AppLink :to="`/${category.url_key}`" class="relative flex justify-center flex-col h-full">
            <NuxtImg
              v-if="category.image"
              :src="category.image"
              :alt="category.name || ''"
              class="h-full w-full border border-transparent rounded-2xs rounded-bl-none"
              format="webp"
              width="217"
              height="319"
              loading="lazy"
            />
            <span class="[ mt-7 w-full text-lg-1 text-center ] [ flex justify-center ]">
              {{ category.name }}
            </span>
          </AppLink>
        </SwiperSlide>

        <!-- <SwiperSlide>
          <AppLink to="/deals" class="swiper-slide relative flex flex-col w-full h-full">
            <img src="/deals.jpg" alt="" class="w-full h-full rounded-md" width="164" height="224" />
            <span
              class="[ mt-5 w-full text-black text-sm text-center uppercase tracking-widest ] [ flex justify-center ]"
            >
              {{ t('deals') }}
            </span>
          </AppLink>
        </SwiperSlide> -->
        <!-- <div class="swiper-scrollbar"></div> -->
      </Swiper>
    </ClientOnly>
  </div>
</template>
<script setup lang="ts">
const { t } = useI18n({ useScope: 'local' });

defineProps({
  arrows: {
    type: Boolean,
    default: false,
  },
  itemsPerRowOnDesktop: {
    type: Number,
    default: 6,
  },
  itemsPerRowOnMobile: {
    type: Number,
    default: 2.4,
  },
  alignment: {
    type: String,
    default: 'start',
    validator: (value: string) => ['start', 'center', 'end'].includes(value),
  },
});
const { data } = useAsyncData(
  async () =>
    await useHomeFeaturedCategories({
      flat: true,
    }),
);

const categories = computed(() => data?.value || []);
// limit featured categories to be max 5
const filteredCategories = computed(() => categories.value.slice(0, 5));
</script>
<style lang="postcss" scoped>
.deals-bullet {
  z-index: 0;
  & > img {
    z-index: -1;
    aspect-ratio: 0.73;
  }
}

.categories-flex {
  @screen md {
    @apply flex flex-wrap;

    & > div,
    & > a {
      @apply px-3;
      height: 240px;
      flex-basis: calc(100% / 6);
    }
  }
}

.swiper-container {
  padding-block-end: 36px !important;
  height: 265px;
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  bottom: 3px;
  left: 5%;
  z-index: 50;
  height: 2px;
  width: 98%;

  @media (min-width: 1024px) {
    width: 100%;
    left: 0;
  }

  & > .swiper-scrollbar-drag {
    background: black !important;
  }
}

.swiper-slide {
  width: 155px;
  @screen md {
    width: 217px;
  }
}
</style>
<i18n>
{
  "en": {
    "title": "Our Featured Categories",
    "deals": "Best Deals",
    "subtitle": "- Browse"
  },
  "ar": {
    "title": "فئاتنا",
    "deals": "أفضل العروض",
    "subtitle": "- تصفح"
  }
}
</i18n>
